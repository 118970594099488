<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					:model="searchForm"
					@submit.native.prevent
        >
          <el-form-item label="姓名:">
            <el-input
							v-model.trim="searchForm.personnelusername"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="年份:">
            <el-input
                    v-model.trim="searchForm.year"
                    @keyup.enter.native="seach"
                    clearable />
          </el-form-item>
          <el-form-item label="休假月份:">
            <el-date-picker
              clearable
              v-model="searchForm.holidaymonth"
              type="month"
              format="yyyy-MM"
              value-format="yyyy-MM"
            />
          </el-form-item>
          <el-form-item label="教学点:">
            <el-input
							v-model.trim="searchForm.schoolorgname"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{ padding: 0 }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column
        label="姓名"
        prop="personnelusername"
        align="center"
        width="120"
      >
        <template slot-scope="scope" align="center">
          <el-button @click="btnShowDetail(scope.row)" type="text">
            {{ scope.row.personnelusername }}
          </el-button>
        </template>
      </el-table-column>

      <el-table-column
        label="月份"
        prop="holidaymonth"
        align="center"
        width="100"
      />
      <el-table-column
        label="休假数"
        prop="countholiday"
        align="center"
        width="100"
      />
      <el-table-column
        label="法定"
        prop="legalholiday"
        align="center"
        width="100"
      />
      <el-table-column
        label="请假"
        prop="countleave"
        align="center"
        width="100"
      />
      <el-table-column
        label="年假"
        prop="annualleave"
        align="center"
        width="100"
      />
      <el-table-column
        label="应休"
        prop="recholiday"
        align="center"
        width="100"
      />
      <el-table-column
        label="实休"
        prop="shouldholiday"
        align="center"
        width="100"
      />
      <el-table-column
        label="剩余"
        prop="surplusholiday"
        align="center"
        width="100"
      />
      <el-table-column
        label="扣费"
        prop="feededuction"
        align="center"
        width="100"
      />
      <el-table-column
        label="上月结"
        prop="lastbalance"
        align="center"
        width="100"
      />
      <el-table-column
        label="本月结"
        prop="balanceday"
        align="center"
        width="100"
      />
      <el-table-column
        label="教学点"
        prop="schoolorgname"
        align="center"
        width="120"
      />
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
      :width="$store.state.global.isFullscreen ? 'width: 100%' : 'width: 80%'"
      :fullscreen="$store.state.global.isFullscreen"
      :visible.sync="showDetailModal"
			:close-on-click-modal='false'
      v-if="showDetailModal"
      append-to-body
      @closed="$store.state.global.isFullscreen = false"
    >
      <span slot="title" class="el-dialog__title">
        <span>{{ dialogTitle }}</span>
        <span
          :class="
            $store.state.global.isFullscreen
              ? 'el-icon-copy-document'
              : 'el-icon-full-screen'
          "
          @click="isExpand($store.state.global.isFullscreen)"
        ></span>
      </span>
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="30%"
        class="demo-dynamic form-item-w-33"
        status-icon
        :rules="rules"
      >
        <el-form-item label="流水号:">
          {{ dynamicValidateForm.id }}
        </el-form-item>
        <el-form-item label="员工:">
          {{ dynamicValidateForm.personnelusername }}
        </el-form-item>
        <el-form-item label="电话号码:">
          {{ dynamicValidateForm.personnelusermobile }}
        </el-form-item>
        <el-form-item label="录入人:">
          {{ dynamicValidateForm.insertusername }}
        </el-form-item>
        <el-form-item label="录入时间:">
          {{
            dynamicValidateForm.inserttime | formatDate("YYYY-MM-DD HH:mm:ss")
          }}
        </el-form-item>
        <el-form-item label="休假月份:">
          {{ dynamicValidateForm.holidaymonth }}
        </el-form-item>
        <el-form-item label="休假天数:">
          {{ dynamicValidateForm.countholiday }}天
        </el-form-item>
        <el-form-item label="法定假日:">
          {{ dynamicValidateForm.legalholiday }}天
        </el-form-item>
        <el-form-item label="请假天数:">
          {{ dynamicValidateForm.countleave }}天
        </el-form-item>
        <el-form-item label="实收天数:">
          {{ dynamicValidateForm.shouldholiday }}天
        </el-form-item>
        <el-form-item label="剩余天数:">
          {{ dynamicValidateForm.surplusholiday }}天
        </el-form-item>
        <el-form-item label="结存天数:">
          {{ dynamicValidateForm.balanceday }}天
        </el-form-item>
        <el-form-item label="应收天数:">
          {{ dynamicValidateForm.recholiday }}天
        </el-form-item>
        <el-form-item label="上月结存:">
          {{ dynamicValidateForm.lastbalance }}天
        </el-form-item>
        <el-form-item label="旷工天数:">
          {{ dynamicValidateForm.absenteeismday }}天
        </el-form-item>
        <el-form-item label="迟到次数:">
          {{ dynamicValidateForm.latenumber }}次
        </el-form-item>
        <el-form-item label="早退次数:">
          {{ dynamicValidateForm.leaveearly }}次
        </el-form-item>
        <el-form-item label="未打卡次数:">
          {{ dynamicValidateForm.notpunch }}次
        </el-form-item>
        <el-form-item label="年假:">
          {{ dynamicValidateForm.annualleave }}天
        </el-form-item>
        <el-form-item label="所扣费用金额:">
          {{ dynamicValidateForm.feededuction }}块钱
        </el-form-item>
        <el-form-item label="教学点:">
          {{ dynamicValidateForm.schoolorgname }}
        </el-form-item>
        <el-form-item label="分公司:">
          {{ dynamicValidateForm.companyorgname }}
        </el-form-item>
        <el-form-item label="备注:">
          {{ dynamicValidateForm.remark }}
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
      width="60%"
			:close-on-click-modal='false'
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
			:title="this.dialogTitle"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="30%"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgid" label="分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgid" label="教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="休假月份" prop="holidaymonth">
          <el-date-picker
            v-model="dynamicValidateForm.holidaymonth"
            type="month"
            format="yyyy-MM"
            value-format="yyyy-MM"
          />
        </el-form-item>
        <el-form-item
          label="员工姓名"
          class="nickName"
          prop="personnelusername"
        >
          <el-input v-model="dynamicValidateForm.personnelusername" disabled />
          <el-button
            type="primary"
            class="select-btn"
            @click="
              onSelectData(
                user_dialogresult4,
                'showUserModal',
                {schoolorgid: dynamicValidateForm.schoolorgid,}
              )
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="上月结存" prop="lastbalance">
          <el-input v-model.trim="dynamicValidateForm.lastbalance" clearable/>
        </el-form-item>
        <el-form-item label="休假天数" prop="countholiday">
          <el-input v-model.trim="dynamicValidateForm.countholiday" clearable/>
        </el-form-item>
        <el-form-item label="法定假日" prop="legalholiday">
          <el-input v-model.trim="dynamicValidateForm.legalholiday" clearable/>
        </el-form-item>
        <el-form-item label="请假天数" prop="countleave">
          <el-input v-model.trim="dynamicValidateForm.countleave" clearable/>
        </el-form-item>
        <el-form-item label="旷工天数" prop="absenteeismday">
          <el-input v-model.trim="dynamicValidateForm.absenteeismday" clearable/>
        </el-form-item>
        <el-form-item label="未打卡次数" prop="notpunch">
          <el-input v-model.trim="dynamicValidateForm.notpunch" clearable/>
        </el-form-item>
        <el-form-item label="迟到次数" prop="latenumber">
          <el-input v-model.trim="dynamicValidateForm.latenumber" clearable/>
        </el-form-item>
        <el-form-item label="年假天数" prop="annualleave">
          <el-input v-model.trim="dynamicValidateForm.annualleave" clearable/>
        </el-form-item>
        <el-form-item label="早退次数" prop="leaveearly">
          <el-input v-model.trim="dynamicValidateForm.leaveearly" clearable/>
        </el-form-item>
        <el-form-item label="实休天数" prop="shouldholiday">
          <el-input v-model.trim="dynamicValidateForm.shouldholiday" clearable/>
        </el-form-item>
        <el-form-item
          class="labelClass"
          label="所扣费用金额"
          prop="feededuction"
        >
          <el-input v-model.trim="dynamicValidateForm.feededuction" clearable/>
        </el-form-item>
        <el-form-item label="备注" class="labelClass">
          <el-input type="textarea" v-model.trim="dynamicValidateForm.remark" clearable/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 选择员工： -->
    <el-dialog
      class="btn-2b5a95"
      width="1200px"
			v-dialogDrag
      @close="onCloseSelect"
			:close-on-click-modal='false'
      title="选择员工："
      :visible.sync="showUserModal"
			v-if="showUserModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmUser">确定</el-button>


<div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="真实姓名:" prop="realname">
              <el-input
								v-model.trim="selectSearchForm.realname"
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,null,Object.assign({schoolorgid:dynamicValidateForm.schoolorgid},selectSearchForm))"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="onSelectSearchClick(selectTableUrl,null,Object.assign({schoolorgid:dynamicValidateForm.schoolorgid},selectSearchForm))
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>



      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				stripe
				:key="key"
				border
				:header-cell-style="{ background: '#044d8c', color: '#fff'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column label="序号" type="index" align="center" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="员工编号" prop="code" align="center" />
        <el-table-column label="登录账号" prop="name" align="center" />
        <el-table-column label="真实姓名" prop="realname" align="center" />
        <el-table-column label="登录账号" prop="name" align="center" />
        <el-table-column label="职位状态名称" prop="stateval" align="center" />
        <el-table-column label="性别名称" prop="sexval" align="center" />
        <el-table-column label="籍贯" prop="comefrom" align="center" />
        <el-table-column label="家庭住址" prop="homeaddr" align="center" />
        <el-table-column label="联系电话" prop="contactphone" align="center" />
        <el-table-column label="专业" prop="major" align="center" />
      </el-table>
      <el-pagination
        background
        @current-change="(val, data)=>handleSelectChange(val, Object.assign({schoolorgid:dynamicValidateForm.schoolorgid},selectSearchForm))"
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <Export
      :exportTitle="'考勤管理'"
      :transferDialog="transferDialog"
      :exportUrl="exportUrl"
      :exportexcelUrl="exportexcelUrl"
      :exportsaveUrl="exportsaveUrl"
      :searchForm="searchForm"
      @onCloseExport="onCloseExport"
    ></Export>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import Export from "@/components/Export/index.vue";
import {
  holiday_list,
  holiday_save,
  holiday_input,
  holiday_delete,
  holiday_show,
  holiday_export,
  holiday_exportexcel,
  holiday_exportsave,
	user_getuser,
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import { _pictureupload_save, _user_dialogresult4 } from "@/request/api/public";

export default {
  mixins: [part],
  name: "holiday",
  components: { Export },
  props: {
    exportUrl: {
      default: () => holiday_export,
    },
    exportexcelUrl: {
      default: () => holiday_exportexcel,
    },
    exportsaveUrl: {
      default: () => holiday_exportsave,
    },
    user_dialogresult4: {
      default: () => _user_dialogresult4,
    },
  },
  data() {
    //数值表单规则
    var checkfield = (rule, value, callback) => {
      if (!(value + "")) {
        return callback(new Error("必填字段"));
      } else {
        if (isNaN(Number(value))) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }
    };
    return {
      transferDialog: false, //导出框

      itemId: null, //首表格项id
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, // 添加
      showDetailModal: false, // 查看详情
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        companyorgid: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        schoolorgid: [{ trigger: "blur", message: "必填字段", required: true }],
        holidaymonth: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        personnelusername: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        lastbalance: [{ trigger: "blur", message: "必填字段", required: true }],
        countholiday: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        legalholiday: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        countleave: [{ trigger: "blur", message: "必填字段", required: true }],
        absenteeismday: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        notpunch: [{ trigger: "blur", message: "必填字段", required: true }],
        latenumber: [{ trigger: "blur", message: "必填字段", required: true }],
        annualleave: [{ trigger: "blur", message: "必填字段", required: true }],
        leaveearly: [{ trigger: "blur", message: "必填字段", required: true }],
        shouldholiday: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        feededuction: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
      }, //表单规则
      dialogTitle: "", //对话框标题

      searchForm: {},
      selectTableUrl: null,
			key: '',
      selectSchoolorgid: null,
      selectSearchForm: {},
      selectTable: {},
      showUserModal: false,
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
    };
  },
  created() {
    this.getDataList();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["org_combox", "org_comboxchild"]),
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
    //关闭导出框
    onCloseExport(bool) {
      this.transferDialog = false;
    },
    //导出 click
    btnExport() {
      this.transferDialog = true;
    },
		reset() {
			this.searchForm = {
				personnelusername: null,
				holidaymonth: null,
				schoolorgname: null
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		selectQuery() {
			this.selectSearchForm = {
				realname: null
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(this.selectTableUrl, null, {schoolorgid: this.dynamicValidateForm.schoolorgid});
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: holiday_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },

    // 查看详情路由页 api
    btnShowDetail(row) {
      myRequest({
        url: holiday_input,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showDetailModal = true;
          this.dialogTitle = "查看";
          this.dynamicValidateForm = {};
          this.dynamicValidateForm = res.data.data.vholiday;
        }
      });
    },

    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = {
        rank: 0,
        lastbalance: 0,
        countholiday: 6,
        legalholiday: 0,
        countleave: 0,
        absenteeismday: 0,
        notpunch: 0,
        latenumber: 0,
        annualleave: 0,
        leaveearly: 0,
        feededuction: 0,
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
      };
      this.$set(
        this.dynamicValidateForm,
        "companyorgname",
        this.$store.state.login.userinfo.companyorgname
      );
      this.$set(
        this.dynamicValidateForm,
        "schoolorgname",
        this.$store.state.login.userinfo.schoolorgname
      );
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },

    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //确认员工 click
    onConfirmUser() {
      selectCheck(this.multipleSelection, "确认员工", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "personnelusername",
          this.multipleSelection[0].realname
        );
        this.dynamicValidateForm.personneluserid = this.multipleSelection[0].id;
				this.getComplaint(this.multipleSelection[0].id);
        this.showUserModal = false;
				this.selectQuery();
				this.multipleSelection = [];
      });
    },
		//选择的员工 api
		getComplaint(id) {
		  myRequest(
		    {
		      url: user_getuser,
		      data: {
		        id: id,
		      },
		    },
		    {
		      disableTip: true,
		    }
		  ).then((res) => {
				//console.log(res,'哈哈----')
		    if (res.data.code === "200") {
					this.dynamicValidateForm.lastbalance	 = res.data.data.lastbalance;
		    }
		  });
		},

    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: holiday_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showAddModal = true;
          this.dynamicValidateForm = res.data.data.vholiday;
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.isoktime; //isoktime 该字段不用传
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: holiday_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,

              modal: "showAddModal",
            }
          );
        }
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: holiday_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //切换选项表格页
    handleSelectChange(val,data) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl,null,data);
			this.onCloseSelect();
    },
    //选中表格数据改变时 change
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },


    //选项表格 api
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url,modal,data){
      this.selectPageInfo.pageindex = 1
      this.onSelectData(url,modal,data)
    },
    //关闭选项框 click
    onCloseSelect(){
      this.selectPageInfo.pageindex = 1
    },

  },
};
</script>


<style  lang="scss">
</style>
